<template>
<div class="flex flex-col bg-base-100 gap-2 min-h-screen">
  <!--search bar-->
  <div class="flex flex-col justify-center items-center  mt-2 fixed w-full top-0 gap-2 ">
    
    <input v-model="query" @input="fetch_gifs" type="text" class="rounded-lg w-11/12 p-2 border-2 
    border-inherit h-12 mx-2" 
    placeholder="Find The Perfect GIF For The Artboard!">
    
  
  <!--gif carousel-->
  <div 
  v-show="show_carousel"
  class="flex flex-row rounded-lg gap-2 w-11/12 justify-center items-center bg-base-100
 overflow-x-auto space-x-4">

 <div v-for="index in 5" :id="index" class="skeleton lg:w-32 lg:h-32 m-10 w-32 h-32" v-show="show_carousel"></div>

<img v-for="gif in gif_suggestion" :key="gif['id']"
:src="gif['images']['original']['url']" class="w-20 lg:w-32 rounded-lg my-2 hover:cursor-pointer" 
:v-show="gif['images'] != null"
v-on:click="add_gif(gif['images']['original']['url'])">


  </div>
  </div>
  <!--artboard-->
  <div class="columns-3xs mx-4 gap-8 min-h-max">
    <div v-for="index in 20" :id="index" class="skeleton w-32 h-32 m-10" v-show="background_gifs_loading"></div>
    
    <img class="my-2 rounded-lg last:border-4 border-amber-200" 
    v-for="gif in background_gifs"
    :src="gif"
    >
    
    
  </div>
</div>
</template>

<script setup>

import { ref,onMounted  } from 'vue'
import { GiphyFetch } from '@giphy/js-fetch-api'

import {save_gif,get_gifs,supabase} from '@/supabase'



onMounted(()=>{
  get_latest_gif()
  get_gifs().then(data=>{
      if(data['error']==null){
        background_gifs.value = []
        
        data['data'].reverse().forEach((gif)=>{
          
        background_gifs.value.push(gif['url'])}

        )
        background_gifs_loading.value = false
      }}).then(()=>window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }))
})

const gf = new GiphyFetch('zuhAzZgeyN362DnC5pnscvN773c4dqpz')

let gif_suggestion = ref(null)
let show_carousel = ref(false)
let query = ref('')
let timeout = ref(null)
let background_gifs = ref([])
let background_gifs_loading = ref(true)



function add_gif(url){
  
  show_carousel.value = false
  query.value = ''
  save_gif(url)
}

function fetch_gifs(){
  
if (query.value !=''){
  gif_suggestion.value = null
  
  show_carousel.value=true

  clearTimeout(timeout);

 timeout = setTimeout(()=>{
  
  fetch(`https://api.giphy.com/v1/gifs/search?api_key=${process.env.VUE_APP_GIPHY_KEY}&q=${query.value}&limit=10&offset=0&rating=g&lang=en`).
  then(response=>response.json()).then(data=>{gif_suggestion.value=data['data']})
 },1000)
 
}

else
show_carousel.value=false
}


function get_latest_gif(){
    const channel = supabase
  .channel('schema-db-changes')
  .on(
    'postgres_changes',
    {
      event: 'INSERT',
      schema: 'public',
      table:'gifs'
    },
    async (payload) => {
      

      background_gifs.value.push(payload.new['url'])
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }
    
  )
  .subscribe()
}
</script>
