import { createClient } from '@supabase/supabase-js'

// Create a single supabase client for interacting with your database
export const supabase = createClient(process.env.VUE_APP_SUPABASE_URL, process.env.VUE_APP_SUPABASE_ANON_KEY)


export async function save_gif(gif_url){

    const { error } = await supabase
    .from('gifs')
    .insert({ url: gif_url })
}

export async function get_gifs(){
    
const { data, error } = await supabase
.from('gifs')
.select('*')
.order('id', { ascending: false })
.limit(20)

return {data,error}
}


